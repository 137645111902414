export const getFallbackMetaTitle = (locale: string) => {
	switch (locale) {
		case "de":
		default:
			return "Secureo | Mit Sicherheit zufrieden"
	}
}

export const getFallbackMetaDescription = (locale: string) => {
	switch (locale) {
		case "de":
		default:
			return "Wir liefern die Sicherheitstechnik von morgen. Egal ob Design-Tresore oder digitale Türzylinder, die sich mit dem Smartphone öffnen lassen."
	}
}
