import Link from "components/i18n/Link"
import { IconButton, Drawer, ListItem, List, ListItemText } from "@mui/material"

import { makeStyles } from "makeStyles"

import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"

import categories from "config/navCategories.json"
import serviceLinks from "config/serviceLinks.json"

import useToggle from "@secureo/common/hooks/useToggle"

const useStyles = makeStyles()(() => ({
	drawer: {
		width: "100%",
		flexShrink: 0,
		overflow: "scroll",
	},
	drawerPaper: {
		width: "100%",
		marginTop: 83,
		background: "#182F37",
		color: "#fff",
	},
	center: {
		position: "absolute",
		top: "40%",
		left: "50%",
		width: "100%",
		transform: "translate(-50%, -50%)",
	},
}))

const MobileNav = () => {
	const { classes } = useStyles()
	const [isOpen, toggleDrawer] = useToggle()
	const [categoryDrawerIsOpen, toggleCategoryDrawer] = useToggle(false)
	const [serviceDrawerIsOpen, toggleServiceDrawer] = useToggle(false)

	return (
		<>
			<IconButton style={{ color: "white", padding: 5 }} onClick={toggleDrawer} size="large">
				{isOpen ? <AiOutlineClose size="1.7rem" /> : <AiOutlineMenu size="1.7rem" />}
			</IconButton>
			<Drawer
				anchor={"left"}
				open={isOpen}
				onClose={toggleDrawer}
				style={{ width: "100%", position: "relative" }}
				className={classes.drawer}
				classes={{
					paper: classes.drawerPaper,
				}}
				BackdropProps={{ invisible: true }}
			>
				<div className={classes.center}>
					<List style={{ fontSize: "2rem" }}>
						{/* Produkte */}
						<ListItem button onClick={toggleCategoryDrawer}>
							<ListItemText
								style={{
									textAlign: "left",
									paddingLeft: 25,
								}}
							>
								<div style={{ fontSize: "22px" }}>
									Produkte <span style={{ marginLeft: 10 }}>&#10230;</span>
								</div>
							</ListItemText>
						</ListItem>
						<Drawer
							anchor={"left"}
							open={categoryDrawerIsOpen}
							onClose={toggleCategoryDrawer}
							style={{ width: "100%", position: "relative" }}
							className={classes.drawer}
							classes={{
								paper: classes.drawerPaper,
							}}
							BackdropProps={{ invisible: true }}
						>
							<div style={{ paddingBottom: 180 }}>
								<ListItem button onClick={toggleCategoryDrawer}>
									<ListItemText
										style={{
											textAlign: "left",
											paddingLeft: 25,
											marginTop: 30,
										}}
									>
										<span style={{ marginRight: 10 }}>&#10229;</span>
										<strong>Zurück</strong>
									</ListItemText>
								</ListItem>
								{/* categories */}
								{categories.map(({ name, slug }) => {
									return (
										<Link key={slug} href={`/c/${slug}`} onClick={toggleDrawer}>
											<ListItem button>
												<ListItemText
													style={{
														textAlign: "left",
														paddingLeft: 25,
													}}
													primary={name}
												/>
											</ListItem>
										</Link>
									)
								})}
							</div>
						</Drawer>
						{/* Service */}
						<ListItem button onClick={toggleServiceDrawer}>
							<ListItemText style={{ textAlign: "left", paddingLeft: 25 }}>
								<div style={{ fontSize: "22px" }}>
									Service <span style={{ marginLeft: 10 }}>&#10230;</span>
								</div>
							</ListItemText>
						</ListItem>
						<Drawer
							anchor={"left"}
							open={serviceDrawerIsOpen}
							onClose={toggleServiceDrawer}
							style={{ width: "100%", position: "relative" }}
							className={classes.drawer}
							classes={{
								paper: classes.drawerPaper,
							}}
							BackdropProps={{ invisible: true }}
						>
							<ListItem button onClick={toggleServiceDrawer}>
								<ListItemText
									style={{ textAlign: "left", paddingLeft: 25, marginTop: 30 }}
								>
									<span style={{ marginRight: 10 }}>&#10229;</span>{" "}
									<strong>Zurück</strong>
								</ListItemText>
							</ListItem>
							{serviceLinks.map(({ name, slug }) => {
								return (
									<Link key={slug} href={slug} onClick={toggleDrawer}>
										<ListItem button>
											<ListItemText
												style={{
													textAlign: "left",
													paddingLeft: 25,
												}}
												primary={name}
											/>
										</ListItem>
									</Link>
								)
							})}
						</Drawer>
						<Link href="/contact" onClick={toggleDrawer}>
							<ListItem button>
								<ListItemText style={{ textAlign: "left", paddingLeft: 25 }}>
									<div style={{ fontSize: "22px" }}>Kontakt</div>
								</ListItemText>
							</ListItem>
						</Link>
					</List>
				</div>
			</Drawer>
		</>
	)
}

export default MobileNav
