import { AppProps } from "next/app"
import { useRouter } from "next/router"
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter"

import Layout from "components/Layout"
import { DefaultSEO } from "components/SEO"

import { pushEventToDataLayer } from "@secureo/common/components/GoogleTagManager"
import isProduction from "@secureo/common/utils/isProduction"

import "styles/global.scss"

interface Props extends AppProps {
	err?: any
}

const CustomApp = (props: Props) => {
	const { Component, pageProps } = props

	const isSeriesPage = (pageProps as any)?.series?.id?.length > 0

	const { asPath } = useRouter()

	return (
		<AppCacheProvider {...props}>
			<DefaultSEO asPath={asPath} />
			<Layout isSeriesPage={isSeriesPage}>
				<Component {...pageProps} />
			</Layout>
		</AppCacheProvider>
	)
}

export function reportWebVitals({ id, name, label, value }) {
	// Only report web vitals in production
	if (isProduction()) {
		pushEventToDataLayer({
			event: "web-vitals",
			event_category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
			event_action: name,
			// Google Analytics metrics must be integers, so the value is rounded.
			// For CLS the value is first multiplied by 1000 for greater precision
			// (note: increase the multiplier for greater precision if needed).
			event_value: Math.round(name === "CLS" ? value * 1000 : value),
			// The `id` value will be unique to the current page load. When sending
			// multiple values from the same page (e.g. for CLS), Google Analytics can
			// compute a total by grouping on this ID (note: requires `eventLabel` to
			// be a dimension in your report).
			event_label: id,
		})
	}
}

export default CustomApp
