import React from "react"
import { InstantSearch } from "react-instantsearch-dom"

import Link from "components/i18n/Link"
import Button from "components/Buttons/Button"
import MobileNav from "./MobileNav"
import SearchBox from "../SearchBox/SearchBox"

import useToggle from "@secureo/common/hooks/useToggle"

import algoliaSearchClient from "@secureo/common/utils/algolia/searchClient"
import searchConfig from "config/searchConfig"

import {
	Box,
	Grid,
	AppBar,
	IconButton,
	List,
	ListItem,
	ListItemText,
	useScrollTrigger,
} from "@mui/material"
import { makeStyles } from "makeStyles"
import { AiOutlineSearch } from "react-icons/ai"
import { CgPhone } from "react-icons/cg"

import Logo from "svg/logo.svg"
import { Phone } from "../i18n/Contact"

import categories from "config/navCategories.json"
import serviceLinks from "config/serviceLinks.json"

import { BsChevronDown } from "react-icons/bs"

// This searchClient only returns results when a query is entered
const searchClient = {
	search(requests) {
		if (
			requests.every(
				({ params }) =>
					!params.query || params.query.length < searchConfig.minCharactersBeforeSearch,
			)
		) {
			return Promise.resolve({
				results: requests.map(() => ({
					hits: [],
					nbHits: 0,
					nbPages: 0,
					processingTimeMS: 0,
				})),
			})
		}

		return algoliaSearchClient.search(requests)
	},
}

const useStyles = makeStyles()(() => ({
	listItem: {
		background: "inherit",
		padding: "0rem 1rem",
		"&:hover": {
			background: "rgba(255,255,255, 0.1)",
		},
	},
	hideOnMobile: {
		display: "block",
		"@media only screen and (max-width: 960px)": {
			display: "none !important",
		},
	},
	hideOnDesktop: {
		display: "block !important",
		"@media only screen and (min-width: 961px)": {
			display: "none !important",
		},
	},
}))

function ElevationScroll(props) {
	const { children } = props
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	})

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0,
	})
}

const Navbar = ({ isSeriesPage }: Props) => {
	const { classes } = useStyles()

	const [searchView, toggleSearchView] = useToggle()

	return (
		<InstantSearch indexName={"SEC-series"} searchClient={searchClient}>
			<ElevationScroll>
				<AppBar
					position={isSeriesPage ? "absolute" : "sticky"}
					style={{ background: "rgba(8, 33, 41, 0.9)", color: "white", height: 81 }}
				>
					<Box px={2} py={2}>
						<Grid container spacing={3}>
							<Grid item xs={3} md={searchView ? 3 : 2}>
								<Link href="/">
									<Logo
										style={{
											maxHeight: 45,
											minHeight: "100%",
											maxWidht: "90px",
											height: "50px",
											objectFit: "contain",
										}}
									/>
								</Link>
							</Grid>
							{searchView ? (
								<Grid item xs={9}>
									<SearchBox toggleSearchView={toggleSearchView} />
								</Grid>
							) : (
								<>
									<Grid
										item
										xs={2}
										md={3}
										style={{ display: "flex", alignItems: "center" }}
										className={classes.hideOnMobile}
									>
										{/* Produkte */}
										<div className="dropdown">
											<Link href={`/c`}>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														marginRight: 15,
													}}
												>
													Produkte
													<BsChevronDown
														size="1rem"
														color="#fff"
														style={{ marginLeft: 5 }}
													/>
												</div>
											</Link>
											<div className="dropdown-content">
												<List component="nav" aria-label="navbar-links">
													{categories.map(({ name, slug }) => {
														return (
															<Link key={slug} href={`/c/${slug}`}>
																<ListItem
																	button
																	className={classes.listItem}
																>
																	<ListItemText primary={name} />
																</ListItem>
															</Link>
														)
													})}
												</List>
											</div>
										</div>

										{/* Service */}
										<div className="dropdown">
											<Link href={`/service`}>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														marginRight: 15,
													}}
												>
													Service{" "}
													<BsChevronDown
														size="1rem"
														color="#fff"
														style={{ marginLeft: 5 }}
													/>
												</div>
											</Link>
											<div className="dropdown-content">
												<List component="nav" aria-label="navbar-links">
													{serviceLinks.map(({ name, slug }) => {
														return (
															<Link key={slug} href={slug}>
																<ListItem
																	button
																	className={classes.listItem}
																>
																	<ListItemText primary={name} />
																</ListItem>
															</Link>
														)
													})}
												</List>
											</div>
										</div>

										<Box mr={2} style={{ marginBottom: 0 }}>
											<Link href="/contact">Kontakt</Link>
										</Box>
										<IconButton
											style={{ color: "white" }}
											onClick={toggleSearchView}
											size="large"
										>
											<AiOutlineSearch size="1.5rem" />
										</IconButton>
									</Grid>

									<Grid item xs={2} md={7} className={classes.hideOnMobile}>
										<Box
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-end",
												marginTop: 5,
											}}
										>
											<Box mr={1}>
												<CgPhone size="1.8rem" color="#14A0B0" />
											</Box>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "flex-end",
												}}
											>
												<Box mr={3}>
													<span style={{ fontSize: 14 }}>
														Kostenlose Beratung!
														{/* {openingHours} */}
													</span>{" "}
													<br />{" "}
													<strong>
														<Phone />
													</strong>
												</Box>
												<div>
													<a
														href="#whereToBuy"
														style={{ textDecoration: "none" }}
													>
														<Button color="primary">Wo Kaufen?</Button>
													</a>
												</div>
											</div>
										</Box>
									</Grid>
								</>
							)}
							{!searchView && (
								<Grid
									item
									xs={searchView ? 1 : 9}
									className={classes.hideOnDesktop}
									style={{
										textAlign: "right",
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
									}}
								>
									<>
										<IconButton
											style={{ color: "white" }}
											onClick={toggleSearchView}
											size="large"
										>
											<AiOutlineSearch size="1.5rem" />
										</IconButton>

										<MobileNav />
									</>
								</Grid>
							)}
						</Grid>
					</Box>
				</AppBar>
			</ElevationScroll>
		</InstantSearch>
	)
}

interface Props {
	isSeriesPage: boolean
}

export default Navbar
