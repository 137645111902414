import React, { useEffect, useState } from "react"
import { connectHits } from "react-instantsearch-dom"
import { ConnectedComponentClass, HitsProvided } from "react-instantsearch-core"

import Link from "components/i18n/Link"

import searchConfig from "config/searchConfig"

import { AlgoliaCategoryHit } from "typings/Algolia"

const SearchBoxCategoryResults = ({
	hits,
	searchQuery,
	closeSearchBar,
}: Props & HitsProvided<AlgoliaCategoryHit>) => {
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		let timeOutId = null
		setIsLoading(true)
		timeOutId = setTimeout(() => {
			setIsLoading(false)
		}, searchConfig.timeoutBeforeNoResultsDisplayMs)
		return () => {
			clearTimeout(timeOutId)
		}
	}, [searchQuery])

	const onClick = () => {
		closeSearchBar()
	}

	return (
		<div>
			{hits.map((hit) => {
				return (
					<Link href={`/c/${hit.slug}`} key={hit.objectID} onClick={onClick}>
						<h3>{hit.name}</h3>
					</Link>
				)
			})}
		</div>
	)
}

interface Props {
	searchQuery: string
	closeSearchBar: () => void
}

const ExposedSearchBoxCategoryResults: ConnectedComponentClass<
	Record<string, unknown>,
	HitsProvided<AlgoliaCategoryHit>
> = connectHits(SearchBoxCategoryResults)

export default ExposedSearchBoxCategoryResults
