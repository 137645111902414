import NextLink, { LinkProps } from "next/link"

const Link = ({ href, children, style = {}, ...props }: Props) => {
	return (
		<NextLink
			style={{ color: "inherit", textDecoration: "none", ...style }}
			href={href}
			{...props}
		>
			{children}
		</NextLink>
	)
}

interface Props extends LinkProps {
	children: React.ReactNode
	style?: React.CSSProperties
	rel?: string
}

export default Link
