import { SEOProps } from "../../components/SEO"
import { NextSeoProps } from "next-seo"

const twitterSite = "secureogmbh"

const fallbackTwitterCardType = ""

const getTwitterSEOProps = (props: SEOProps) => {
	const cardType = props?.twitter?.cardType ?? fallbackTwitterCardType

	const twitterSEOProps: NextSeoProps = {
		twitter: {
			site: twitterSite,
			cardType,
		},
	}

	return twitterSEOProps
}

export default getTwitterSEOProps
