export { openingHours, fallBackPhoneNumber as phoneNumber } from "@secureo/common/config/contact"

// Social Media
export const socialMediaPages = {
	facebook: "https://facebook.com/secureogmbh",
	twitter: "https://twitter.com/secureogmbh",
	linkedIn: "https://www.linkedin.com/company/secureo-gmbh/",
	xing: "https://www.xing.com/xbp/pages/secureo-gmbh",
	instagram: "https://www.instagram.com/secureogmbh/",
}

// Email
export const emailAddress = "office@secureo.at"
export const supportEmailAddresses = [emailAddress]
export const accountingEmailAddress = "kainz@secureo.at"
export const managementEmailAddress = "reinalter@secureo.at"
export const marketingEmailAddress = "michael.reinalter@secureo.at"
export const careerEmailAddress = "bewerbung@secureo.at"
