import React from "react"
import { Button as MaterialUIButton } from "@mui/material"

import { makeStyles } from "makeStyles"

type ButtonColor = "default" | "primary" | "secondary"

const useStyles = makeStyles()((theme) => ({
	// Lightgrey
	default: {
		background: "#EDEFF0",
		color: "#505E5F",
		textTransform: "inherit",
		paddingLeft: 20,
		paddingRight: 20,
		border: "none",
		width: "100%",
		fontFamily: "OpenSans",

		"&:hover": {
			background: "#EDEFF0",
			color: theme.palette.secondary.dark,
			border: "none",
		},
	},
	// Blue
	primary: {
		background: theme.palette.primary.main,
		color: "#fff",
		paddingLeft: 20,
		paddingRight: 20,
		textTransform: "inherit",
		border: "none",
		width: "100%",
		fontFamily: "OpenSans",

		"&:hover": {
			background: "#006F7B",
			color: "#fff",
			border: "none",
		},
	},
	// Dark
	secondary: {
		background: theme.palette.secondary.main,
		color: "#fff",
		paddingLeft: 20,
		paddingRight: 20,
		textTransform: "inherit",
		border: "none",
		width: "100%",
		fontFamily: "OpenSans",

		"&:hover": {
			background: "#14A0B0",
			color: "#fff",
			border: "none",
		},
	},
}))

const getButtonClassName = (
	classes: {
		default: string
		primary: string
		secondary: string
	},
	color: ButtonColor,
) => {
	switch (color) {
		case "primary":
			return classes.primary
		case "secondary":
			return classes.secondary
		default:
			return classes.default
	}
}
const Button = ({ color, type = "button", size, children, style, ...props }: Props) => {
	const { classes } = useStyles()
	const className = getButtonClassName(classes, color)

	return (
		<MaterialUIButton
			className={className}
			type={type}
			{...props}
			size={size}
			style={{ borderRadius: 3, fontSize: 16, fontFamily: "Mulish, sans-serif", ...style }}
		>
			{children}
		</MaterialUIButton>
	)
}

// TODO: types
interface Props {
	children: React.ReactNode
	color?: ButtonColor
	onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	style?: React.CSSProperties
	type?: "button" | "submit" | "reset"
	disabled?: boolean
	disableRipple?: boolean
	tabIndex?: number
	className?: string
	size?: "small" | "medium" | "large"
}

export default Button
