import { ThemeProvider } from "@mui/material/styles"
import { makeStyles } from "makeStyles"

import Navbar from "./Navbar"
import WhereToBuy from "./WhereToBuy"
import Footer from "./Footer"
import theme from "theme"

const useStyles = makeStyles()(() => ({
	headerBackgroundSpace: {
		height: 81,
		width: "100%",
		background: "#082129",
		position: "absolute",
		top: 0,
		left: 0,
		"@media only screen and (max-width: 960px)": {
			height: 83,
		},
	},
	ring: {
		height: "300px",
		marginRight: 20,
		position: "absolute",
		top: -100,
		right: -100,
		opacity: 0.7,
		zIndex: 2,
		"@media only screen and (max-width: 960px)": {
			opacity: 0.2,
			top: -120,
			right: -120,
		},
	},
}))

const Layout = ({ children, isSeriesPage }: Props) => {
	const { classes } = useStyles()
	return (
		<ThemeProvider theme={theme}>
			<Navbar isSeriesPage={isSeriesPage} />
			<div className={classes.headerBackgroundSpace} />
			<main
				style={{
					minHeight: "55vh",
				}}
			>
				{children}
			</main>
			<WhereToBuy background="#182F37" />
			<Footer />
		</ThemeProvider>
	)
}

interface Props {
	children: React.ReactNode
	isSeriesPage: boolean
}

export default Layout
