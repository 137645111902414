import { NextSeoProps } from "next-seo"
import { SEOProps } from "../../../components/SEO"

const getFallbackSeriesMetaTitle = (seriesName: string, locale: string) => {
	switch (locale) {
		case "de":
		default:
			return `${seriesName} Serie | Secureo`
	}
}

export const getFallbackSeriesMetaDescription = (seriesName: string, locale: string) => {
	switch (locale) {
		case "de":
		default:
			return `${seriesName} von Secureo. Ihr Profi für Tresore und Waffenschränke. Mit Sicherheit zufrieden!`
	}
}

const getSeriesNextSEOProps = (props: SEOProps) => {
	const { title, description, seriesName } = props

	const seriesSEOProps: NextSeoProps = {}

	if (title) {
		seriesSEOProps.title = title
	} else if (seriesName) {
		seriesSEOProps.title = getFallbackSeriesMetaTitle(seriesName, "de")
	}
	if (description) {
		seriesSEOProps.description = description
	} else if (seriesName) {
		seriesSEOProps.description = getFallbackSeriesMetaDescription(seriesName, "de")
	}

	return seriesSEOProps
}

export default getSeriesNextSEOProps
