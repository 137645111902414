import React from "react"
import { makeStyles } from "makeStyles"
import { AiOutlineInstagram, AiFillLinkedin, AiFillTwitterCircle } from "react-icons/ai"
import { FaFacebook } from "react-icons/fa"

import { socialMediaPages } from "config/contact"

const useStyles = makeStyles()(() => ({
	icon: {
		color: "#fff",
		transtion: "0.3s",
		"&:hover": {
			color: "#009CAC",
		},
	},
}))

const SocialMedia = () => {
	const { classes } = useStyles()
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<a
				href={socialMediaPages.facebook}
				style={{ margin: "10px 10px 0px 0px" }}
				target="_blank"
				rel="noopener noreferrer"
				className={classes.icon}
			>
				<FaFacebook size="1.2rem" />
			</a>

			<a
				href={socialMediaPages.twitter}
				style={{ margin: "10px 10px 0px 0px" }}
				target="_blank"
				rel="noopener noreferrer"
				className={classes.icon}
			>
				<AiFillTwitterCircle size="1.2rem" />
			</a>

			<a
				href={socialMediaPages.linkedIn}
				style={{ margin: "10px 10px 0px 0px" }}
				target="_blank"
				rel="noopener noreferrer"
				className={classes.icon}
			>
				<AiFillLinkedin size="1.2rem" />
			</a>

			<a
				href={socialMediaPages.instagram}
				style={{ margin: "10px 10px 0px 0px" }}
				target="_blank"
				rel="noopener noreferrer"
				className={classes.icon}
			>
				<AiOutlineInstagram size="1.2rem" />
			</a>
		</div>
	)
}

export default SocialMedia
