import Link from "components/i18n/Link"
import {
	Container,
	Box,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	List,
	ListItemText,
	ListItemButton,
} from "@mui/material"
import { makeStyles } from "makeStyles"
import { Email, Phone } from "components/i18n/Contact"

import Secureo from "svg/layout/Secureo-ring.svg"
import { BsChevronDown } from "react-icons/bs"

import SocialMedia from "components/SocialMedia"

const services = [
	{
		href: "/spare-parts",
		label: "Ersatzteile anfragen",
	},
	{
		href: "/manuals",
		label: "Bedienungsanleitungen",
	},
	{
		href: "/solutions",
		label: "Branchenlösungen",
	},
	// {
	// 	href: "/",
	// 	label: "Tresorwissen",
	// },
	{
		href: "/register-products",
		label: "Produktregistrierung",
	},
]

const shops = [
	{
		href: "https://www.tresoro.de",
		label: "Tresoro",
	},
	{
		href: "https://safehero.com",
		label: "SafeHero",
	},
]

const categories = [
	{
		href: "/c/wertschutzschraenke",
		label: "Wertschutzschränke",
	},
	{
		href: "/c/langwaffenschraenke",
		label: "Langwaffenschränke",
	},
	{
		href: "/c/hotelsafes",
		label: "Hotelsafes",
	},
	{
		href: "/c/haengeregister",
		label: "Hängeregister",
	},
	{
		href: "/c/schluesselsafes",
		label: "Schlüsselsafes",
	},
]

const useStyles = makeStyles()(() => ({
	desktop: {
		display: "block",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	mobile: {
		display: "none",
		"@media only screen and (max-width: 960px)": {
			display: "block",
		},
	},
	accordion: {
		background: "none",
		boxShadow: "none",
		color: "#fff",
		paddingLeft: 0,
	},
	summary: {
		padding: "0px 0px",
	},
	details: {
		background: "rgba(255,255,255,0.1)",
		borderRadius: 5,
		margin: 0,
	},
	link: {
		color: "#fff",
		transtion: "0.3s",
		textDecoration: "none",
		width: "100%",
		"&:hover": {
			color: "#009CAC",
		},
	},
}))

const Footer = () => {
	const { classes } = useStyles()
	return (
		<div style={{ background: "#082129", color: "white", paddingTop: 40, paddingBottom: 40 }}>
			<Box>
				<Container>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Secureo
									style={{
										height: "70px",
										marginRight: 20,
									}}
								/>
								<div>
									<div className="h2" style={{ fontWeight: 300, fontSize: 22 }}>
										Secureo Gmbh
									</div>
									<div style={{ marginTop: 5, opacity: 0.75 }}>
										Mit Sicherheit zufrieden
									</div>
								</div>
							</div>
							<Box style={{ marginTop: 30 }}>
								<strong>Nehmen Sie Kontakt mit uns auf</strong>
							</Box>
							<Box my={2} style={{ opacity: 0.75 }}>
								Tel.: <Phone /> | E-Mail: <Email />
							</Box>
							<SocialMedia />
						</Grid>
						<Grid item xs={12} sm={6} md={2}>
							<div className={classes.desktop}>
								<Link href="/service">
									<div className="h3" style={{ marginBottom: 15 }}>
										Services
									</div>
								</Link>
								<div style={{ lineHeight: 2 }}>
									{services.map((service) => {
										return (
											<div key={service.label} className={classes.link}>
												<Link href={service.href}>{service.label}</Link>
											</div>
										)
									})}
								</div>
							</div>
							<div className={classes.mobile}>
								<Accordion className={classes.accordion}>
									<AccordionSummary
										expandIcon={<BsChevronDown color="#fff" />}
										aria-controls="services"
										id="services"
										className={classes.summary}
									>
										<Link href="/service">Services</Link>
									</AccordionSummary>
									<AccordionDetails className={classes.details}>
										<List style={{ width: "100%" }}>
											{services.map((service) => {
												return (
													<ListItemButton
														key={service.label}
														className={classes.link}
													>
														<Link href={service.href}>
															<ListItemText primary={service.label} />
														</Link>
													</ListItemButton>
												)
											})}
										</List>
									</AccordionDetails>
								</Accordion>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={2}>
							<div className={classes.desktop}>
								<div className="h3" style={{ marginBottom: 15 }}>
									Wo kaufen?
								</div>
								<div style={{ lineHeight: 2 }}>
									{shops.map((shop) => {
										return (
											<div key={shop.label}>
												<a
													href={shop.href}
													target="__blank"
													className={classes.link}
												>
													{shop.label}
												</a>
											</div>
										)
									})}
									<div style={{ marginTop: 0 }}>
										<a
											href="https://www.amazon.de/secureo"
											target="__blank"
											rel="nofollow"
											className={classes.link}
										>
											Amazon
										</a>
									</div>
								</div>
							</div>
							<div className={classes.mobile}>
								<Accordion className={classes.accordion}>
									<AccordionSummary
										expandIcon={<BsChevronDown color="#fff" />}
										aria-controls="where-to-buy"
										id="where-to-buy"
										className={classes.summary}
									>
										Wo kaufen?
									</AccordionSummary>
									<AccordionDetails className={classes.details}>
										<List style={{ width: "100%" }}>
											{shops.map((shop) => {
												return (
													<ListItemButton
														key={shop.label}
														className={classes.link}
													>
														<Link href={shop.href}>
															<ListItemText primary={shop.label} />
														</Link>
													</ListItemButton>
												)
											})}
											<ListItemButton className={classes.link}>
												<a
													href="https://www.amazon.de/secureo"
													target="__blank"
													rel="nofollow"
												>
													<ListItemText primary="Amazon" />
												</a>
											</ListItemButton>
										</List>
									</AccordionDetails>
								</Accordion>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={2}>
							<div className={classes.desktop}>
								<div className="h3" style={{ marginBottom: 15 }}>
									Produktkategorien
								</div>
								<div style={{ lineHeight: 2 }}>
									{categories.map((category) => {
										return (
											<div key={category.label} className={classes.link}>
												<Link href={category.href}>{category.label}</Link>
											</div>
										)
									})}
								</div>
							</div>
							<div className={classes.mobile}>
								<Accordion className={classes.accordion}>
									<AccordionSummary
										expandIcon={<BsChevronDown color="#fff" />}
										aria-controls="categories"
										id="categories"
										className={classes.summary}
									>
										Produktkategorien
									</AccordionSummary>
									<AccordionDetails className={classes.details}>
										<List style={{ width: "100%" }}>
											{categories.map((category) => {
												return (
													<ListItemButton
														key={category.label}
														className={classes.link}
													>
														<Link href={category.href}>
															<ListItemText
																primary={category.label}
															/>
														</Link>
													</ListItemButton>
												)
											})}
										</List>
									</AccordionDetails>
								</Accordion>
							</div>
						</Grid>
					</Grid>
					<div style={{ borderBottom: "1px solid #ECEEEF", margin: "15px 0px" }} />
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<Link href="/contact" style={{ marginRight: 10 }}>
								Kontakt
							</Link>
							<Link href="/imprint" style={{ marginRight: 10 }}>
								Impressum
							</Link>
							<Link href="/data-policy" style={{ marginRight: 10 }}>
								Datenschutz
							</Link>
						</Grid>
						<Grid item xs={12} sm={6}></Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	)
}

export default Footer
