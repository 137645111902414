import { createTheme } from "@mui/material/styles"

const theme = createTheme({
	spacing: 8,
	palette: {
		primary: {
			main: "#009CAC", // light blue
		},
		secondary: {
			main: "#042C33", // dark
		},
		success: {
			main: "#44B87A", // green
		},
		error: {
			main: "#DE6052", // red
		},
	},
	typography: {
		fontFamily: ["Open Sans"].join(","),
		h1: {
			fontFamily: ["Bitter"].join(","),
			fontWeight: 800,
			fontSize: "45px",
		},
		h2: {
			fontFamily: ["Bitter"].join(","),
			fontWeight: 800,
			fontSize: "38px",
		},
		h3: {
			fontFamily: ["Bitter"].join(","),
			fontWeight: 800,
			fontSize: "30px",
		},
		h4: {
			fontFamily: ["Bitter"].join(","),
			fontWeight: 700,
			fontSize: "20px",
		},
		h5: {
			fontFamily: ["Open Sans"].join(","),
			fontWeight: 800,
			fontSize: "18px",
		},
		button: {
			fontFamily: ["Bitter"].join(","),
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					size: "medium",
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					dense: true,
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: { dense: true },
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: { dense: true },
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: { size: "small" },
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: { dense: true },
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: { dense: true },
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: { padding: "dense" },
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: { dense: true },
			},
		},
		MuiTable: {
			styleOverrides: {
				root: { size: "medium" },
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: { dense: true },
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: { variant: "dense" },
			},
		},
		// If we want to disable the Ripples -> enable below
		// MuiButtonBase: {
		//   disableRipple: true 💣!
		// }
	},
})

export default theme
