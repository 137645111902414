import React from "react"

import { Grid, Container } from "@mui/material"

import { makeStyles } from "makeStyles"

import Amazon from "/svg/shops/Amazon-light.svg"
import SafeHero from "/svg/shops/SafeHero.svg"
import Tresoro from "/svg/shops/Tresoro.svg"
import DigiShop from "/svg/shops/logo-digitalzylinder-shop.svg"

import { IoMdOpen } from "react-icons/io"

const shops = [
	{
		title: "Tresoro",
		svg: Tresoro,
		link: "https://www.tresoro.de",
		color: "#149FB8",
	},
	{
		title: "SafeHero",
		svg: SafeHero,
		link: "https://safehero.com/",
		color: "#7ABFAC",
	},
	{
		title: "Amazon",
		svg: Amazon,
		link: "https://www.amazon.de/secureo",
		color: "#FD9908",
	},
	{
		title: "Digitalzylinder-Shop",
		svg: DigiShop,
		link: "https://digitalzylinder-shop.com/",
		color: "#4691CE",
	},
]

const useStyles = makeStyles()(() => ({
	card: {
		border: "1px solid rgba(255, 255, 255, 0.2)",
		borderRadius: 3,
		height: 120,
		width: "100%",
		boxShadow: "0 10px 10px rgba(0, 0, 0, 0.17)",
		position: "relative",
		"&:hover": {
			border: "1px solid rgba(255, 255, 255, 1)",
			"& $svg": {
				// transform: "translate(-65%, -50%)",
				filter: "brightness(100%)",
			},
			"@media only screen and (min-width: 960px)": {
				"& $openIcon": {
					display: "block",
				},
			},
		},
	},
	svg: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		transition: "0.3s",
		width: "70%",
		"@media only screen and (max-width: 960px)": {
			padding: 40,
			width: "30%",
		},
	},
	openIcon: {
		background: "rgba(255, 255, 255, 0.1)",
		height: 30,
		width: 30,
		position: "absolute",
		bottom: 0,
		right: 0,
		borderBottomRightRadius: 3,
		padding: 3,
		textAlign: "center",
		display: "none",
	},
}))

const WhereToBuy = ({ background }: Props) => {
	const { classes } = useStyles()

	return (
		<div
			id="whereToBuy"
			style={{
				background: background,
				color: "#fff",
				padding: "70px 0px",
			}}
		>
			<Container>
				<Grid container spacing={3}>
					<Grid item xs={12} md={5}>
						<div className="h2" style={{ fontSize: 24, fontWeight: 300 }}>
							Wo kann man unsere Secureo Produkte kaufen?
						</div>
						<p style={{ opacity: 0.75 }}>
							Unsere diversen Tresore und Waffenschränke erhalten Sie online bei
							folgenden Anbietern, so wie auf Anfrage.
						</p>
					</Grid>
					<Grid item xs={12} md={1} />
					{shops.map((shop) => {
						const { svg: SVG, link, color, title } = shop
						return (
							<Grid
								item
								xs={12}
								sm={6}
								md={1.5}
								key={link}
								style={{
									width: "100%",
									position: "relative",
								}}
							>
								<a href={link} target="__blank" rel="noopener noreferrer">
									<div className={classes.card}>
										<SVG className={classes.svg} title={title} />
										<div className={classes.openIcon}>
											<IoMdOpen
												size="1.4rem"
												color={color}
												style={{ marginTop: 3 }}
											/>
										</div>
									</div>
								</a>
							</Grid>
						)
					})}
				</Grid>
			</Container>
		</div>
	)
}

interface Props {
	background: any
}

export default WhereToBuy
