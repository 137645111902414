import { NextSeoProps } from "next-seo"

import getOpenGraphSEOProps from "./getOpenGraphSEOProps"
import getTwitterSEOProps from "./getTwitterSEOProps"
import getLanguageAlternatesMetaTags from "./getLanguageAlternatesMetaTags"

import { SEOProps } from "../../components/SEO"
import getPageTypeSpecificSEOProps from "./pageSpecific/getPageTypeSpecificSEOProps"

const prependHostToPath = (path: string) => `${process.env.NEXT_PUBLIC_HOST}${path}`

const getNextSEOProps = (props: SEOProps) => {
	const {
		asPath,
		noindex = false,
		countryCode = "DE",
		excludeLanguageAlternates = false,
		canonicalAppendix = "",
	} = props

	const canonical = prependHostToPath(asPath.split("?")[0]) + canonicalAppendix
	const pathIncludesQueryParams = asPath.includes("?")

	const languageAlternates =
		pathIncludesQueryParams || excludeLanguageAlternates
			? []
			: getLanguageAlternatesMetaTags(canonical, asPath)

	const pageSpecificSEOProps = getPageTypeSpecificSEOProps(props, countryCode)

	const openGraphSEOProps = getOpenGraphSEOProps({ ...props, ...pageSpecificSEOProps }, canonical)
	const twitterSEOProps = getTwitterSEOProps({ ...props, ...pageSpecificSEOProps })

	const nextSEOProps: NextSeoProps = {
		canonical,
		languageAlternates,
		...openGraphSEOProps,
		...twitterSEOProps,
		...pageSpecificSEOProps,
		noindex,
	}

	return nextSEOProps
}

export default getNextSEOProps
