import { AnchorHTMLAttributes, DetailedHTMLProps } from "react"
import removeWhiteSpace from "@secureo/common/utils/removeWhiteSpace"

import { phoneNumber, emailAddress as defaultEmailAddress } from "config/contact"

export const Phone = ({
	style = {},
	...props
}: Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "href">) => {
	return (
		<a
			href={`tel:${removeWhiteSpace(phoneNumber)}`}
			data-hj-whitelist
			style={{ color: "inherit", textDecoration: "none", ...style }}
			{...props}
		>
			{phoneNumber}
		</a>
	)
}

export const Email = ({
	email = defaultEmailAddress,
	style = {},
	...props
}: Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "href"> & {
	email?: string
}) => {
	return (
		<a
			href={`mailto:${email}`}
			data-hj-whitelist
			style={{ color: "inherit", textDecoration: "none", ...style }}
			{...props}
		>
			{email}
		</a>
	)
}
