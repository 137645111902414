import React from "react"
import { NextSeo, DefaultSeo as DefaultNextSeo, NextSeoProps } from "next-seo"

import getNextSEOProps from "../utils/seo/getNextSEOProps"
import { CountryCode } from "@secureo/common/typings/CountryCode"

const SEO = (props: SEOProps) => {
	const nextSeoProps = getNextSEOProps(props)

	return <NextSeo {...nextSeoProps} />
}

export interface SEOProps extends DefaultSEOProps {
	asPath: string
	appendLocalizedShopNameWithPipe?: boolean
	excludeLanguageAlternates?: boolean
	/**
	 * Can be used to optionally append e.g. query params to a canonical urls
	 */
	canonicalAppendix?: string
	pageType?: string
	productName?: string
	categoryName?: string
	seriesName?: string
	countryCode?: CountryCode
}

export default SEO

export const DefaultSEO = (props: DefaultSEOProps) => {
	const nextSeoProps = getNextSEOProps(props)

	return <DefaultNextSeo {...nextSeoProps} />
}

interface DefaultSEOProps extends NextSeoProps {
	asPath: string
	countryCode?: CountryCode
}
