import { useState, useEffect } from "react"
import { FormControl, InputBase, InputAdornment, IconButton } from "@mui/material"
import { makeStyles } from "makeStyles"
import { connectSearchBox, Index } from "react-instantsearch-dom"
import { SearchBoxProvided, SearchBoxExposed } from "react-instantsearch-core"
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai"

import SearchBoxResults from "components/SearchBox/SearchBoxResults"
import SearchBoxCategoryResults from "components/SearchBox/SearchBoxCategoryResults"

import useDebounce from "@secureo/common/hooks/useDebounce"

import searchConfig from "config/searchConfig"

const useStyles = makeStyles()(() => ({
	textField: {
		width: "100%",
		background: "none",
		color: "white",
		border: "none",
		padding: "5px 0px",
		marginTop: 5,
		paddingLeft: 10,
	},
	inputStyle: {
		color: "white !important",
		zIndex: 0,
		opacity: 1,
		fontSize: "14px",
	},
}))

const SearchBoxInput = ({
	onSubmit,
	searchQuery,
	setSearchQuery,
	toggleSearchView,
}: SearchBoxInputProps) => {
	const { classes } = useStyles()

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { value } = e.target
		setSearchQuery(value)
	}

	const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { keyCode, which, code } = e
		if (keyCode === 13 || which === 13 || code === "Enter") onSubmit()
	}

	return (
		<FormControl className={classes.textField} variant="filled">
			<InputBase
				id="search-box"
				startAdornment={
					<InputAdornment position="start">
						<IconButton
							onClick={onSubmit}
							style={{ padding: 5, marginTop: -15 }}
							size="large"
						>
							<AiOutlineSearch size="1.5rem" color="#fff" />
						</IconButton>
					</InputAdornment>
				}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							style={{ padding: 5, color: "white" }}
							onClick={toggleSearchView}
							size="large"
						>
							<AiOutlineClose size="1.5rem" color="#fff" />
						</IconButton>
					</InputAdornment>
				}
				onSubmit={onSubmit}
				onChange={onChange}
				value={searchQuery}
				inputProps={{ onKeyPress, "data-hj-whitelist": true, "data-hj-allow": true }}
				placeholder="Suchen Sie nach Modellen und Kategorien"
				className={classes.inputStyle}
			/>
		</FormControl>
	)
}

interface SearchBoxInputProps {
	onSubmit: () => void
	searchQuery: string
	setSearchQuery: (searchQuery: string) => void
	toggleSearchView: () => void
}

// see packages/safehero/components/SearchBar/Results/SearchResults.tsx for inspiration on how to implement multi index search
const SearchBox = ({ currentRefinement, refine, toggleSearchView }: Props & SearchBoxProvided) => {
	const [searchQuery, setSearchQuery] = useState(currentRefinement)
	const debouncedSearchQuery = useDebounce(searchQuery, searchConfig.debounceSearchMs)

	useEffect(() => {
		refine(debouncedSearchQuery)
	}, [debouncedSearchQuery, refine])

	const onSubmit = () => {
		if (searchQuery.length > 0) {
			// This does not work currently on client side transitions + getServerSideProps,
			// because query params are not passed to the lambda function, use hard refresh for now, re-enable later
			// router.push("/search", `/search?query=${searchQuery}`)
			// setSearchQuery("")
			window.location.href = `/search?query=${searchQuery}`
		}
		toggleSearchView()
	}

	return (
		<>
			<SearchBoxInput
				onSubmit={onSubmit}
				searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				toggleSearchView={toggleSearchView}
			/>
			{searchQuery.length >= searchConfig.minCharactersBeforeSearch && (
				<>
					<Index indexName="SEC-series">
						<SearchBoxResults
							searchQuery={searchQuery}
							closeSearchBar={toggleSearchView}
						/>
					</Index>
					<Index indexName="SEC-categories">
						<SearchBoxCategoryResults
							searchQuery={searchQuery}
							closeSearchBar={toggleSearchView}
						/>
					</Index>
				</>
			)}
		</>
	)
}

interface Props {
	toggleSearchView: () => void
}

const ExposedSearchBox: React.ComponentClass<Props & SearchBoxExposed> = connectSearchBox(SearchBox)

export default ExposedSearchBox
