import { useEffect, useState } from "react"
import { connectHits } from "react-instantsearch-dom"
import { ConnectedComponentClass, HitsProvided } from "react-instantsearch-core"

import { Box, Container, ClickAwayListener } from "@mui/material"
import { makeStyles } from "makeStyles"
import Image from "next/image"

import Link from "components/i18n/Link"

import searchConfig from "config/searchConfig"

import { AlgoliaSeriesHit } from "typings/Algolia"

import Spinner from "components/Spinner"

const useStyles = makeStyles()(() => ({
	searchBox: {
		position: "fixed",
		top: 81,
		left: "auto",
		right: "auto",
		width: 800,
		height: 400,
		background: "rgba(8, 33, 41, 0.9)",
		marginLeft: "auto",
		marginRight: "auto",
		padding: "50px 0px",
		boxShadow: "0 0px 8px rgba(0, 0, 0, 0.27)",
		overflowY: "auto",
		"@media only screen and (max-width: 1200px)": {
			width: "100%",
			left: 0,
		},
	},
	item: {
		background: "none",
		borderRadius: 5,
		padding: 10,
		display: "flex",
		alignItems: "center",
		transition: "0.3s",
		"&:hover": {
			background: "rgba(0, 157, 172, 0.4)",
			boxShadow: "0 0px 8px rgba(0, 0, 0, 0.27)",
		},
	},
	image: {
		width: 75,
	},
}))

const SearchBoxResults = ({
	hits,
	searchQuery,
	closeSearchBar,
}: Props & HitsProvided<AlgoliaSeriesHit>) => {
	const { classes } = useStyles()
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		let timeOutId = null
		setIsLoading(true)
		timeOutId = setTimeout(() => {
			setIsLoading(false)
		}, searchConfig.timeoutBeforeNoResultsDisplayMs)
		return () => {
			clearTimeout(timeOutId)
		}
	}, [searchQuery])

	const onClick = () => {
		closeSearchBar()
	}

	return (
		<ClickAwayListener onClickAway={closeSearchBar}>
			<div className={classes.searchBox}>
				<Container>
					{hits.length === 0 ? (
						<Box mt={5} style={{ color: "#fff", textAlign: "center" }}>
							Es wurden <strong>keine Ergebnisse</strong> zu Ihrer Suchanfrage
							gefunden.
						</Box>
					) : (
						<>
							{isLoading ? (
								<Box mt={5}>
									<Spinner />
								</Box>
							) : (
								<div>
									{hits.map((hit) => {
										return (
											<Link
												href={`/s/${hit.slug}`}
												onClick={onClick}
												key={hit.objectID}
											>
												<div className={classes.item}>
													<div className={classes.image}>
														<Image
															src={hit.image}
															alt={hit.name}
															width={70}
															height={70}
															style={{
																maxWidth: "100%",
																height: "auto",
																objectFit: "contain",
															}}
														/>
													</div>
													<div style={{ marginLeft: 10 }}>{hit.name}</div>
												</div>
											</Link>
										)
									})}
								</div>
							)}
						</>
					)}
				</Container>
			</div>
		</ClickAwayListener>
	)
}

interface Props {
	searchQuery: string
	closeSearchBar: () => void
}

const ExposedSearchBoxResults: ConnectedComponentClass<
	Record<string, unknown>,
	HitsProvided<AlgoliaSeriesHit>
> = connectHits(SearchBoxResults)

export default ExposedSearchBoxResults
